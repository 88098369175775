<template>
  <v-dialog
    overlay-color="primary"
    overlay-opacity=".97"
    v-model="bActive"
    max-width="73%">
    <v-card
      style="background-color: rgba(240, 240, 240, .85); padding: 5%">
      <v-container>
        <v-layout
          row
          justify-center>
          <p
            style="font-size: 20px !important; font-weight: normal !important"
            class="section_titles"
            v-text="'¿Deseas eliminar ' + project.name + '?'"
          />
        </v-layout>
        <v-container>
        <v-layout
          style="margin-top: 10px; margin-bottom: 10px"
          row
          justify-center>
          <p
            style="font-size: 15 !important; font-weight: normal !important"
            class="section_titles"
            v-text="'Una vez confirmando la acción, el cambio será irreversbile.'"
          />
        </v-layout>
        </v-container>
        <v-layout
          row
          justify-center>
          <v-layout
            row
            justify-center
            style="max-width: 50%">
            <v-btn
              @click="close"
              large
              dark
              color="primary">
              Cancelar
            </v-btn>
            <v-spacer/>
            <v-btn
              :loading="load_delete"
              :disabled="load_delete"
              @click="confirm_delete"
              large
              dark
              color="primary">
              Confirmar
            </v-btn>
          </v-layout>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    load_delete: false
  }),
  methods: {
    confirm_delete () {
      this.load_delete = true
      db.delete(`${uri}/api/v1/projects` + this.project._id, {
        headers: {
          'Authorization': this.usr_token,
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      })
        .then((response) => {
          this.load_delete = false
          this.close()
          this.$store.commit('toggle_alert', {color: 'success', text: "Se eliminó el proyecto " + this.project.name})
          this.update()
        })
        .catch((error) => {
          this.load_delete = false
          this.$store.commit('toggle_alert', {color: 'fail', text: error.response.data.message})
        })
    },
    close () {
      this.$emit('close')
    },
    update () {
      this.$emit('update')
    }
  },
  props: {
    bActive: Boolean,
    project: Object,
    usr_token: String
  }
}
</script>
